<template>
  <v-card>
    <v-card-title class="text-h5">
      Installazione applicazione cassa
    </v-card-title>
    <v-card-subtitle>Esegui il setup inserendo i dati della licenza</v-card-subtitle>
    <v-list class="transparent">
      <v-list-item>
        <v-list-item-title>Link</v-list-item-title>
        <v-list-item-subtitle>
          <!-- <a :href="'https://licenze.ubisell.ubi-co.com/setup?k8s=true&ver=v2&key=' + (cliente.licenze && cliente.licenze[0].apikey)">Setup file</a> -->
          <a :href="downPath()">Setup file</a>
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Apikey</v-list-item-title>
        <v-list-item-subtitle>
          {{ cliente.licenze && cliente.licenze[0].apikey }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Base db</v-list-item-title>
        <v-list-item-subtitle>
          {{ cliente.licenze && cliente.licenze[0].baseDb }}
        </v-list-item-subtitle>
      </v-list-item>
      <v-list-item>
        <v-list-item-title>Utility</v-list-item-title>
        <v-list-item-subtitle>
          <a :href="downPathSU()">UbisellSU</a>
        </v-list-item-subtitle>
      </v-list-item>      
    </v-list>
    <v-card-text></v-card-text>
    <v-card-actions>
      <v-btn text>
        
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
  export default {
    props: {
      cliente: Object
    },
    data() {
      return {

      }
    },
    methods: {
      downPath() {
        const service = config.VUE_APP_service_licenze
        // return `${service}/setup?k8s=true&ver=v2&key=${this.cliente.licenze && this.cliente.licenze[0].apikey}`
        const nomeSetup = this.cliente.isMoleculer ? 'ubisellML.exe' : 'ubisell.exe'
        return `${service}/utility/repository/file?fn=${nomeSetup}&k8s=true&ver=v2&key=${this.cliente.licenze && this.cliente.licenze[0].apikey}${this.cliente.isMoleculer ? '&mol=true' : ''}`
      },
      downPathSU() {
        const service = config.VUE_APP_service_licenze
        return `${service}/utility/repository/file?fn=UbisellSU.exe&k8s=true&ver=v2&key=${this.cliente.licenze && this.cliente.licenze[0].apikey}${this.cliente.isMoleculer ? '&mol=true' : ''}`
      }
    }
  }
</script>
