<template>
  <v-data-table
    :headers="headers"
    :items="moduli"
    item-key="nome"
    sort-by="tipo"
    class="elevation-1"
    dense
    hide-default-footer
    :items-per-page="-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <v-dialog v-model="dialogTipoLicenza" max-width="1000px">
          <template v-slot:activator="{ attrs }">
            <v-btn v-if="['customer', 'developer'].includes(tipoLicenzaInserito)" color="success" dark small class="mb-2" v-bind="attrs" dense @click="sceltaTipoLicenza" title="">
              Scegli tipo licenza
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Scelta tipologia licenza</span>
            </v-card-title>
            <v-card-subtitle>In base alla tipologia scelta vengono abilitati i moduli relativi</v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="tipoLicenza" :items="tipiLicenza" label="Scegli il tipo di licenza" single-line item-value="codice" item-text="descrizione" return-object></v-select>                    
                  </v-col>
<!--                   <v-col cols="12" sm="6" md="4">
                    <pApikey @change="onChangeModuleKey" :short="true" :label="'Chiave modulo'" :hint="'Crea una nuova chiave modulo'" :apikey="editedItem.licenza.moduleKey" :trigger="triggerAggiorna"/>
                  </v-col>    -->               
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataAcquistoFormatted" label="Data acquisto" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataScadenzaFormatted" label="Data scadenza" readonly></v-text-field>
                  </v-col>
<!--                   <v-col cols="12" sm="6" md="4"> 
                    <v-switch v-model="editedItem.licenza.attiva" inset label="Attivo" readonly></v-switch>                    
                  </v-col> -->
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeTipoLicenza"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveTipoLicenza"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogCodicePersonalizzato" max-width="1000px">
          <template v-slot:activator="{ attrs }">
            <v-btn v-if="['customer', 'developer'].includes(tipoLicenzaInserito)" color="info" dark small class="mb-2 ml-3" v-bind="attrs" dense @click="nuovoCodicePersonalizzato">
              Usa codice personalizzato
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">Inserimento codice personalizzato</span>
            </v-card-title>
            <v-card-subtitle>Il codice personalizzato è disponibile in base agli accordi commerciali con la nostra azienda</v-card-subtitle>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-text-field v-model="codicePersonalizzato" label="Codice personalizzato"></v-text-field>                  
                  </v-col>                
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataAcquistoFormatted" label="Data acquisto" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataScadenzaFormatted" label="Data scadenza" readonly></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <span v-show="codiceNonTrovato" class="red--text">Codice personalizzato non trovato !!</span>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="closeCodicePersonalizzato"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="saveCodicePersonalizzato"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialog" max-width="1000px">
          <template v-slot:activator="{ attrs }">
            <v-btn v-if="['customer', 'developer', 'totem'].includes(tipoLicenzaInserito)" color="blue-grey" dark small class="mb-2 ml-3" v-bind="attrs" dense @click="nuovoModulo">
              Nuovo modulo singolo
            </v-btn>
          </template>
          <v-card>
            <v-card-title>
              <span class="text-h5">{{ formTitle }}</span>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12" sm="6" md="4">
                    <v-select v-model="editedItem.nome" :items="isTotem ? moduliLicenzaTotem : moduliLicenza" label="Scegli il modulo" single-line></v-select>                    
                  </v-col>
                  <v-col cols="12" sm="6" md="4">
                    <pApikey @change="onChangeModuleKey" :short="true" :label="'Chiave modulo'" :hint="'Crea una nuova chiave modulo'" :apikey="editedItem.licenza.moduleKey" :trigger="triggerAggiorna"/>
                  </v-col>                  
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataAcquistoFormatted" label="Data acquisto" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"> 
                    <v-text-field v-model="dataScadenzaFormatted" label="Data scadenza" readonly></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6" md="4"> 
                    <v-switch v-model="editedItem.licenza.attiva" inset label="Attivo" readonly></v-switch>                    
                  </v-col>
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="blue darken-1"
                text
                @click="close"
              >
                Annulla
              </v-btn>
              <v-btn
                color="blue darken-1"
                text
                @click="save"
              >
                Salva
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>        
        <v-spacer></v-spacer>
        <v-dialog v-model="dialogDelete" max-width="600px">
          <v-card>
            <v-card-title class="text-h5">Sei sicuro di voler cancellare questo modulo?</v-card-title>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete">Annulla</v-btn>
              <v-btn color="blue darken-1" text @click="deleteItemConfirm">OK</v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
    <template v-slot:[`item.licenza.dataScadenza`]="{ item }">
      {{item.licenza.dataScadenza | date}}
    </template>
    <template v-slot:[`item.licenza.dataAcquisto`]="{ item }">
      {{item.licenza.dataAcquisto | date}}
    </template>
    <template v-slot:[`item.licenza.attiva`]="{ item }">
      <v-simple-checkbox v-model="item.licenza.attiva" disabled />
    </template>
    <template v-slot:[`item.actions`]="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        mdi-pencil
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        mdi-delete
      </v-icon>
    </template>
    <template v-slot:no-data>
      Nessuna licenza definita
    </template>
  </v-data-table>
</template>

<script>
  /* eslint-disable */
  import pApikey from '@/views/drawer/elementi/apikey'
  import moment from 'moment'
  import licenze from '@/services/licenzeService'

  export default {
    components: {
      pApikey
    },
    props: {
      licenza: Object,
      moduli: Array,
      tipoLicenzaCliente: String,
      codiceCustom: String
    },
    data: () => ({
      dialog: false,
      dialogDelete: false,
      dialogTipoLicenza: false,
      dialogCodicePersonalizzato: false,
      headers: [
        {
          text: 'Tipo',
          align: 'start',
          sortable: false,
          value: 'nome',
        },
        { text: 'Chiave modulo', value: 'licenza.moduleKey' },
        { text: 'Data acquisto', value: 'licenza.dataAcquisto' },
        { text: 'Data scadenza', value: 'licenza.dataScadenza' },
        { text: 'Attivo', value: 'licenza.attiva' },
        { text: 'Actions', value: 'actions', sortable: false }
      ],
      moduliLicenza: [],
      moduliLicenzaTotem: [],
      tipiLicenza: [],
      licenzeCustom: [],
      itemListino: null,
      tipoLicenza: null,
      updateTrigger: 0,
      editedIndex: -1,
      // TODO: RIVEDERE IL CARICAMENTO DEI MODULI E LA MODALITA' DI CREAZIONE DELLA DIALOG DI INSERIMENTO LICENZA--> METTERE COMPONENTI
      editedItem: {
        nome: '',
        licenza: {
          tipo: 'customer',
          moduleKey: '',
          dataAcquisto:  moment().utcOffset(0, true).format(),
          dataScadenza:  moment().add(365, 'days').utcOffset(0, true).format(),
          attiva:  true,
          dataUltimoRinnovo: null,
          pagamento: {}
        }
      },
      defaultItem: {
        nome: '',
        licenza: {
          tipo: 'customer',
          moduleKey: '',
          dataAcquisto:  moment().utcOffset(0, true).format(),
          dataScadenza:  moment().add(365, 'days').utcOffset(0, true).format(),
          attiva: true,
          dataUltimoRinnovo: null,
          pagamento: {}
        }
      },
      codicePersonalizzato: '',
      codiceNonTrovato: false,
      triggerAggiorna: 0
    }),
    async mounted() {
      const elenco = await licenze.getElencoProgetti()
      this.moduliLicenza = elenco.moduliLicenza
      this.moduliLicenzaTotem = elenco.moduliLicenzaTotem

      this.tipiLicenza = elenco.tipiLicenza
      this.licenzeCustom = elenco.licenzeCustom
      
    },
    computed: {
      formTitle () {
        return this.editedIndex === -1 ? 'Nuovo modulo' : 'Modifica modulo'
      },
      dataScadenzaFormatted() {
        return this.editedItem && moment(this.editedItem.licenza.dataScadenza).format('DD/MM/YYYY')
      },
      dataAcquistoFormatted() {
        return this.editedItem && moment(this.editedItem.licenza.dataAcquisto).format('DD/MM/YYYY')
      },
      tipoLicenzaInserito() {
        return this.licenza.tipo
      },
      isTotem() {
        return this.licenza.tipo === 'totem'
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
    },
    methods: {
      nuovoModulo() {
        this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
        this.editedItem.licenza.moduleKey = ''
        this.editedIndex = -1
        this.triggerAggiorna++
        this.dialog = true
      },
      editItem (item) {
        this.editedIndex = this.moduli.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.triggerAggiorna++
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.moduli.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
        this.moduli.splice(this.editedIndex, 1)
        this.closeDelete()
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
          this.editedIndex = -1
        })
      },
      onChangeModuleKey(value) {
        this.$nextTick(() => {
          this.editedItem.licenza.moduleKey = value
        })        
      },
      save () {
        if (this.editedItem && this.editedItem.nome !== '') {
          this.$emit('update-modulo', this.licenza.apikey, this.editedItem)
        }
        this.close()
      },

      closeTipoLicenza () {
        this.dialogTipoLicenza = false
        this.$nextTick(() => {
/*           this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
          this.editedIndex = -1 */
        })
      },
      saveTipoLicenza () {
        if (this.tipoLicenza) {
          const tp = { ...this.defaultItem, ...this.tipoLicenza }
          this.$emit('tipo-licenza', this.licenza.apikey, tp)
        }
        this.closeTipoLicenza()
      },
      closeCodicePersonalizzato () {
        this.dialogCodicePersonalizzato = false
        this.$nextTick(() => {
/*           this.editedItem = JSON.parse(JSON.stringify(this.defaultItem))
          this.editedIndex = -1 */
        })
      },
      saveCodicePersonalizzato () {
        if (this.codicePersonalizzato) {
          const lp = this.licenzeCustom.find(x => x.codice === this.codicePersonalizzato)
          if (lp) {
            const cp = { ...this.defaultItem, ...lp }
            // this.$emit('codice-personalizzato', this.licenza.apikey, cp)
            this.$emit('tipo-licenza', this.licenza.apikey, cp, this.codicePersonalizzato)
          } else {
            this.codiceNonTrovato = true
          }
        }
        this.closeCodicePersonalizzato()
      },
      sceltaTipoLicenza() {
        this.tipoLicenza = this.tipoLicenzaCliente || null
        this.triggerAggiorna++
        this.dialogTipoLicenza = true
      },
      nuovoCodicePersonalizzato() {
        this.codicePersonalizzato = this.codiceCustom || ''
        this.triggerAggiorna++
        this.dialogCodicePersonalizzato = true
        this.codiceNonTrovato = false
      },         
    }
  }
</script>
