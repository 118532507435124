<template>
  <v-row>
    <v-col xs="12" sx="12" md="6" xl="6">
      <v-card flat :loading="loading">
        <v-card-title>
          Carica in repositoryK8 gli aggiornamenti dei file dei moduli Ubisell
        </v-card-title>
        <v-card-text>
          <v-row no-gutters>
            <v-col cols="8">
              <v-file-input
                class="mt-2 text-caption"
                accept=".zip;.exe"
                label="File .zip oppure .exe"
                show-size
                outlined
                multiple
                clearable
                prepend-icon=""
                prepend-inner-icon="$file"
                :hint="elencoFile"
                :persistent-hint="!!elencoFile"
                @change="onChangeMultiple"
                @click:clear.stop="onClearFile"
              >
              </v-file-input>
            </v-col>
            <!-- <v-spacer></v-spacer> -->
             <v-col cols="1"></v-col>
            <v-col>
              <v-switch v-model="isMoleculer" inset label="Versione Moleculer" @change="getDirList"></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
       
        <v-card-title>
          Elenco file nel repository
          <v-btn icon class="ml-3">
            <v-icon @click="getDirList">mdi-refresh</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <file-list :files="files" :moduli="moduli" :isMoleculer="isMoleculer" @delete="onDeleteFile" @refresh="getDirList"/>     
        </v-card-text>    
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import utility from '@/services/utilityService'
  import FileList from './fileList'

  export default {
    components: {
      FileList
    },
    data () {
      return {
        elencoFile: '',
        loading: false,
        files: [],
        moduli: [],
        isMoleculer: false
      }
    },
    async mounted() {
      await this.getDirList()
      await this.getModuleVersion()
    },
    methods: {
      onClearFile() {
        this.elencoFile = ''
      },
      async onChangeMultiple(files) {
        if (!files || files.length === 0) {
          return
        }
        this.loading = true
        const form_data = new FormData();
        for (let ff of files) {
          form_data.append('multiple_zip', ff, ff.name)
        }
        const subPath = this.isMoleculer ? 'moleculer' : ''
        this.elencoFile = await utility.uploadRepositoryMultipleFile(form_data, subPath)
        await this.getDirList()
        await this.getModuleVersion()
        this.loading = false
      },
      async getDirList() {
        try {
          if (this.isMoleculer) {
            const elenco = await utility.getk8FolderFileList('moleculer')
            this.files = elenco.map(x => x.file)
          } else {
            this.files = await utility.getk8FileList()
          }
        } catch {}        
      },
      async onDeleteFile(file) {
        const subPath = this.isMoleculer ? 'moleculer' : ''
        await utility.deleteFile(file, subPath)
        await this.getDirList()
      },
      async getModuleVersion() {
        try {
          this.moduli = await utility.getModuleVersion()
        } catch {}
      }
    }
  }
</script>
