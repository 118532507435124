<template>
  <div>
    <v-simple-table dense>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-left">Modulo</th>
            <th class="text-left">Data<v-icon small color="black" @click="sortFile" class="ml-3" title="Ordina per data">{{iconaSort}}</v-icon></th>
            <th class="text-left">Versione</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(file, i) in sortedFiles" :key="i">
<!--             <td>{{file}}</td> -->
            <td><a :href="downPath(file)">{{file}}</a></td>
            <td>{{dataModulo(file) | date}}</td>
            <td>{{versioneModulo(file)}}</td>
            <td><v-icon title="Cancella il file" @click="onRemoveFile(file)">mdi-delete-outline</v-icon></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>    
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false"
    />
  </div>
</template>

<script>
import DialogConferma from '@/components/dialogConferma'

export default {
  components: {
    DialogConferma
  },
  props: {
    files: Array,
    moduli: Array,
    isMoleculer: Boolean
  },
  data(vm) {
    return {
      dialogConferma: false,
      titoloConferma: '',
      messaggioConferma: '',
      onConferma: vm.onRemoveFile,
      itemToDelete: null,
      sortDir: 0
    }
  },
  computed: {
    iconaSort() {
      return this.sortDir === 1 ? 'mdi-sort-descending' : this.sortDir === 2 ? 'mdi-sort-ascending' : 'mdi-sort'
    },
    sortedFiles() {
      return this.sortDir === 1 ? _.sortBy(this.files, (o) => this.dataModulo(o)) : this.sortDir === 2 ? _.sortBy(this.files, (o) => this.dataModulo(o)).reverse() : this.files
    }
  },
  methods: {
    
    sortFile() {
      this.sortDir = (this.sortDir + 1) % 3
    },
    downPath(file) {
      const service = config.VUE_APP_service_licenze
      return `${service}/utility/repository/file?fn=${file}&k8s=true&ver=v2${this.isMoleculer ? '&mol=true' : ''}`
    },    
    onRefresh() {
      this.$emit('refresh')
    }, 
    onRemoveFile(item) {
      this.titoloConferma = "Cancellazione file modulo"
      this.messaggioConferma = 'Confermi cancellazione del file del modulo ?'
      this.onConferma = this.removeFile
      this.itemToDelete = item
      this.dialogConferma = true
    },
    removeFile() {
      const item = this.itemToDelete
      this.$emit('delete', item)
      this.itemToDelete = null
      this.dialogConferma = false
    },  
    dataModulo(file) {
      const m = this.moduli.find(x => x.file === file)
      return m ? m.data : ''
    },
    versioneModulo(file) {
      const m = this.moduli.find(x => x.file === file)
      return m ? m.versione : ''
    }    
  }
}
</script>
