<template>
  <v-card>
    <v-card-title>
      <v-row no-gutters>
        <v-col cols="3">
          <v-text-field
            v-model="search"
            label="Cerca impostazioni"
            hide-details
            clearable
            clear-icon="mdi-close-circle-outline"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-card-title> 
    <v-row no-gutters>
      <v-col cols="4">
        <v-card-text>
          <v-treeview
            :items="items"
            :search="search"
            :open.sync="open"
            dense
            hoverable
            activatable
            item-key="id"
            item-text="descrizione"
            :open-on-click="true"
            :return-object="true"
            transition
            @update:active="onItemSelected"
            @update:open="onUpdateOpen"
          >
<!--           <template v-slot:prepend="{ item }">
            <v-icon small @click="editItem(item)" title="Modifica l'elemento">mdi-pencil</v-icon>
          </template> -->
            <template v-slot:append="{ item }">
              <v-btn v-if="!item.children || item.children.length === 0" icon @click.stop="deleteFolder(item)" title="Cancella l'elemento"><v-icon small>mdi-delete</v-icon></v-btn>
              <v-btn v-if="aggiungiElementoVisibile(item)" icon @click.stop="onNewItem(item)" title="Aggiungi un elemento figlio" ><v-icon small>mdi-plus-circle-outline</v-icon></v-btn>
            </template>          
          </v-treeview>
        </v-card-text>
      </v-col>
      <v-col cols="3">
        <v-card-subtitle>
          <v-row no-gutters>
            <v-col><span class="mt-2 mb-1 text-caption grey--text text--lighten-1">{{ pathId }}</span></v-col>
            <v-col></v-col>
            <v-col><v-btn @click.stop="creaFileVuoti()" small color="primary" dark>Crea file</v-btn></v-col>
          </v-row>
        </v-card-subtitle>
        <v-data-table
          dense
          :headers="headers"
          :items="filesInFolder"
          item-key="file"
          @click:row="rigaSelezionata"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small @click="onRemoveFile(item)">mdi-delete</v-icon>
          </template>
          <template v-slot:no-data>
            <span>Selezionare la cartella o creare i file necessari !</span>
          </template>      
        </v-data-table>
        <v-card-text>
          <v-textarea v-show="markdownInfo" v-model="markdownInfo" class="px-2 text-caption" outlined auto-grow></v-textarea>      
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click.stop="salvaFile()" small color="success" dark>Salva</v-btn>
        </v-card-actions>
      </v-col>
      <v-col cols="5">
        <v-json-editor v-if="jsonWebS" v-model="jsonWebS" :options="optionsWeb" :plus="true" history @error="onErrorWeb" style="height: 100%;" ref="jsonEditorS"/>
      </v-col>
    </v-row>
    <dialog-conferma
      :show="dialogConferma"
      :title="titoloConferma"
      :message="messaggioConferma"
      @dialog-confirm="onConferma"
      @dialog-cancel="dialogConferma=false"
    />
    <v-dialog v-model="dialogEditItem" max-width="500px">
      <v-card>
        <v-card-title class="text-h5">Nuovo elemento</v-card-title>
        <v-card-text>
          <v-container dense>
            <v-row><v-col><v-text-field v-model="newItem.codice" label="Nome cartella" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row>
            <!-- <v-row><v-col><v-text-field v-model="editedItem.descrizione" label="Descrizione" hide-details clearable clear-icon="mdi-close-circle-outline"></v-text-field></v-col></v-row> -->
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="editItemSave">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>    
  </v-card>
</template>

<script>
import { v4 as uuidv4 } from 'uuid'
import DialogConferma from '@/components/dialogConferma'
import VJsonEditor from 'v-jsoneditor'
import impostazioni from '@/services/impostazioniService'

export default {
  components: {
    VJsonEditor,
    DialogConferma
  },
  props: {
    files: Array,
    moduli: Array
  },
  data(vm) {
    return {
      expanded: true,
      dialogConferma: false,
      dialogEditItem: false,
      editItem: null,
      newItem: {},
      titoloConferma: '',
      messaggioConferma: '',
      onConferma: vm.onRemoveFile,
      itemToDelete: null,
      search: null,
      open: [],
      items: [],
      filesInFolder: [],
      pathId: '',
      jsonMode: 'code',
      optionsWeb: {
        mode: 'code',
        modes: ['view', 'form', 'code'],
        search: true,
        enableSort: false,
        enableTransform: true
      },
      jsonWebS: '',
      markdownInfo: '',
      // mostraJson: false,
      headers: [
        {
          text: 'File',
          align: 'start',
          sortable: false,
          value: 'file'
        },
        { text: 'Data', value: 'data' },
        { text: 'Azioni', value: 'actions', sortable: false, width: "10%" }
      ],
      daSalvare: false,
      defaultRootItem: {
        id: '',
        codice: '',
        descrizione: '',
        children: []
      },
      elementoSelezionato: null,
      itemNuoviFile: null,
      forceUpdate: false,
      separatore: '/'
    }
  },
  computed: {
  },
  watch: {
    async files() {
      const items = this.generaAlberoDir(this.files)
      if (!this.items || this.items.length === 0 || this.forceUpdate) {
        this.forceUpdate = false
        this.items = items
      }
      if (this.itemNuoviFile) {
        await this.refreshContenuto(this.itemNuoviFile)
        this.itemNuoviFile = null
      }
    } 
  },
  mounted() {
    this.items = this.generaAlberoDir(this.files)
  },
  methods: {
    creaFileVuoti(item) {
      this.itemNuoviFile = this.elementoSelezionato
      this.$emit('save', this.pathId)
    },
    salvaFile() {
      this.$emit('save', this.pathId, this.jsonWebS, this.markdownInfo)
    },
    aggiungiElementoVisibile(item) {
      // TODO: verificare come gestire. Non deve essere visibile se ci sono file
      return true
    },
    generaAlberoDir(files) {
      let items = []
      for (let f of files) {
        this.separatore = f.dir.includes('\\') ? '\\' : '/'
        // let d = f.dir.split('\\')
        let d = f.dir.split(this.separatore)
        let rm = items.find(x => x.codice === d[0])
        if (!rm) {
          let al = {
            codice: d[0],
            descrizione: d[0],
            id: uuidv4()
          }
          if (d.length > 1) {
            al.children = this.generaDirRicorsivo(files.map(x => x.dir).filter(x => x.startsWith(d[0])).map(x => x.split(this.separatore).slice(1).join(this.separatore)), this.separatore)
          }
          items.push(al)
        }
      }
      return items.filter(x => x.codice !== '_setup')
    },
    generaDirRicorsivo(files, sep) {
      let items = []
      for (let f of files) {
        let d = f.split(sep)
        let rm = items.find(x => x.codice === d[0])
        if (!rm) {
          let al = {
            codice: d[0],
            descrizione: d[0],
            id: uuidv4()
          }
          if (d.length > 1) {
            al.children = this.generaDirRicorsivo(files.filter(x => x.startsWith(d[0])).map(x => x.split(sep).slice(1).join(sep)), sep)
          }
          items.push(al)
        }
      }
      return items
    },
    onRefresh() {
      this.$emit('refresh')
    }, 
    onRemoveFile(item) {
      this.titoloConferma = "Cancellazione file modulo"
      this.messaggioConferma = 'Confermi cancellazione del file del modulo ?'
      this.onConferma = this.removeFile
      this.itemToDelete = item
      this.dialogConferma = true
    },
    removeFile() {
      const item = this.itemToDelete
      this.$emit('delete', item)
      this.itemToDelete = null
      this.dialogConferma = false
    },  
/*     dataModulo(file) {
      const m = this.moduli.find(x => x.file === file)
      return m ? m.data : ''
    },
    versioneModulo(file) {
      const m = this.moduli.find(x => x.file === file)
      return m ? m.versione : ''
    }, */
    async refreshContenuto(el) {
      const foundEl = this.findRecurse(this.items, el.id)
      const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
      this.pathId = pathId
      this.filesInFolder = this.files.filter(x => x.dir === pathId && x.file.length > 0)
      const elenco = await impostazioni.leggeFileImpostazione(pathId)
      this.jsonWebS = elenco.length > 0 ? elenco[0].dati : ''
      this.markdownInfo = elenco.length > 0 ? elenco[0].info : '' 
    },
    async onItemSelected(elementi) {
      let el = elementi && elementi.length > 0 ? elementi[0] : {}
      
      if (!_.isEmpty(el)) {
        this.elementoSelezionato = el
        await this.refreshContenuto(el)
/*         const foundEl = this.findRecurse(this.items, el.id)
        const pathId = foundEl.pathId.split('.').reverse().join('\\')
        this.pathId = pathId
        this.filesInFolder = this.files.filter(x => x.dir === pathId && x.file.length > 0)
        const elenco = await impostazioni.leggeFileImpostazione(pathId)
        this.jsonWebS = elenco.length > 0 ? elenco[0].dati : ''
        this.markdownInfo = elenco.length > 0 ? elenco[0].info : ''    */     
      } else {
        this.elementoSelezionato = null
      }
    },
    findRecurse(items, id) {
      var item
      for (let el of items) {
        if (el.id === id) {
          item = el
          item.pathId = el.codice
        } else {
          if (el.children) {
            item = this.findRecurse(el.children, id)
            if (item) {
              item.pathId = item.pathId + '.' + el.codice
            }
          }
        }
        if (item) {
          break
        }
      }
      return item
    },
    deleteFolder(item) {
      this.titoloConferma = "Cancellazione cartella"
      this.messaggioConferma = 'Confermi la cancellazione della cartella ?'
      this.onConferma = this.deleteFolderConfirm
      this.itemToDelete = item
      this.dialogConferma = true
    },
    deleteFolderConfirm() {
      this.forceUpdate = true
      const foundEl = this.findRecurse(this.items, this.itemToDelete.id)
      const pathId = foundEl.pathId.split('.').reverse().join(this.separatore)
      this.$emit('deleteFolder', pathId)
      this.itemToDelete = null
      this.dialogConferma = false
    },    
    onUpdateOpen(elementi) {
      this.expanded = elementi.length > 0
    },    
    onNewItem(item) {
      this.editItem = item
      this.newItem = {
        codice: ''
      }
      this.dialogEditItem = true
    },
    editItemSave() {
      let el = {
        id: uuidv4(),
        codice: this.newItem.codice,
        descrizione: this.newItem.codice
      }
      this.editItem.children = this.editItem.children || []
      this.editItem.children.push(el)
      this.dialogEditItem = false
      this.$emit('createFolder', this.editItem.codice + '/' + this.newItem.codice)
      this.daSalvare = true
      this.onUpdateOpen(this.open)
    },
    async rigaSelezionata(item, data, event) {
/*       if (item.file === 'dati.json') {
        const elenco = await impostazioni.leggeFileImpostazione(item.dir)
        this.jsonWebS = elenco[0].dati
        this.markdownInfo = elenco[0].info
        this.mostraJson = true
      } else {
        this.mostraJson = false
      } */
    },
    onErrorWeb() {}
  }
}
</script>
