<template>
   <v-app id="inspire">
      <v-container fluid fill-height>
         <v-layout align-center justify-center>
            <v-flex xs12 sm8 md4>
               <v-card class="elevation-12">
                  <v-toolbar color="#e7e5ba">
                     <v-toolbar-title>Login utente</v-toolbar-title>
                  </v-toolbar>
                  <v-card-title v-if="errorMessage">{{errorMessage}}</v-card-title>
                  <v-card-text>
                     <v-form ref="formLogin" v-model="formLoginFieldsValid" lazy-validation>
                        <v-text-field
                           v-model="email"
                           autofocus
                           prepend-icon="mdi-account-outline"
                           name="login"
                           label="Email"
                           type="text"
                        ></v-text-field>
                        <v-text-field
                           v-model="password"
                           id="password"
                           prepend-icon="mdi-lock-outline"
                           name="password"
                           label="Password"
                           :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                           :rules="[rules.required]"
                           :type="showPassword ? 'text' : 'password'"
                           @click:append="showPassword = !showPassword"
                           @keypress="keyDownPwd"
                        ></v-text-field>
                     </v-form>
                  </v-card-text>
                  <v-card-actions>
                     <v-spacer></v-spacer>
                     <v-btn color="primary" @click="onLogin">Login</v-btn>
                  </v-card-actions>
               </v-card>
            </v-flex>
         </v-layout>
      </v-container>
   </v-app>
</template>

<script>
   import licenze from '@/services/licenzeService'

   export default {
      name: 'Login',
      data () {
         return {
            showPassword: false,
            email: '',
            password:'',
            rules: {
               required: value => !!value || 'Il campo è richiesto'
            },
            formLoginFieldsValid: true,
            errorMessage: ''
         }
      },
      methods: {
         async keyDownPwd(e) {
            if (e.charCode === 13) {
               await this.onLogin()
            }
         },
         async onLogin() {
            const valid = this.$refs.formLogin.validate()
            if (valid) {
               const user = await licenze.login({
                  email: this.email,
                  password: this.password
               })
               if (user.error) {
                  this.errorMessage = user.message
               } else {
                  // this.$store.commit('utenti/setUser', user)
                  this.$store.dispatch('utenti/setUser', user)
                  let redirect = this.$route.query.returnUrl || '/'
                  this.$router.replace(redirect)
               }
            }
         }
      }
   }
</script>

<style></style>
